import * as React from 'react';

const LogoSSvg = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={props.width || 34.796} height={33.12} viewBox='0 0 34.796 33.12' {...props}>
    <g data-name='Group 11129'>
      <path data-name='Path 12707' d='M34.795 18.218H16.21l3.817-3.817h10.951Z' fill='#fff' />
      <path
        data-name='Path 12708'
        d='m24.658 20.582-6.434 6.434-1.721 1.72a.413.413 0 0 1-.585 0l-1.722-1.722-8.8-8.8h-5.4l13.977 13.977a3.159 3.159 0 0 0 4.468 0l2.48-2.479 9.133-9.133Z'
        fill='#fff'
      />
      <path data-name='Path 12709' d='m28.617 12.039-6.624-6.624-2.7 2.7 3.924 3.924Z' fill='#fff' />
      <g data-name='Group 11128'>
        <path data-name='Path 12710' d='m1.438 14.406 8.991-8.992 2.7 2.7-6.292 6.292Z' fill='#fff' />
      </g>
      <path
        data-name='Path 12711'
        d='M18.52.964a3.289 3.289 0 0 0-4.653 0l-2.581 2.581 2.811 2.811 1.792-1.792a.431.431 0 0 1 .609 0l1.791 1.791L21.1 3.544Z'
        fill='#29ccc4'
      />
    </g>
  </svg>
);

export default LogoSSvg;
